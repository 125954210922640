.image-container-web{
  max-width:100%;
  min-width:100%;
  height:180px;
  object-fit: cover;
}

.image-container-mobile{
  max-width:100%;
  min-width:100%;
  height:180px;
  object-fit: cover;
}

.image-container-profile{
  max-width:100%;
  min-width:100%;
  height:140px;
  object-fit: cover;
}

.filled{
  position:absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  right: 0;
  z-index: 1;
}

.filled-overlay{
  background-color: #0a0f16;
  opacity: 0.4;
 flex:1;
}