.photo {
  object-fit: cover
}

.title-sans-black-detail {
  font-family: 'Nunito Sans', sans-serif;
  color:#161c2d;
  font-weight: 600;
  font-size : 20px;
}

.cover {
  max-width: 400px;
  max-height: 400px;
}