.box {
  min-height: 600px;
}
.mini-box {
  min-height: 50px;
}

.min-vw-60 {
  min-width: 60vw !important;
}
.video {
  min-height: 80vh;
  min-width: 50vw;
}