.vacatube-red {
  background-color: #eb3447
}

.light-gray {
  background-color: #FAFAFA;
}

.title-sans-white {
  font-family: 'Nunito Sans', sans-serif;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 30px;
}

