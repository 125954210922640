.paginationJobs{
  color:#EB3447;
  background-color: #FFFFFF;
  box-shadow: none;
  &.active {
    color:#FFFFFF;
    background-color: #EB3447;
    border-color:#EB3447;
    box-shadow: none;
  }
  &:hover {
    border-color: #EB3447;
    color:#EB3447;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }

}

.title-sans-jobs {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 35px;
}